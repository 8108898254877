import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useCoralBreakpoints, Stack, Button, Container, } from '@krakentech/coral';
import { useIsClient } from '@krakentech/utils';
import { IconChevronLeft, IconChevronRight } from '@krakentech/icons';
import { reviewData } from '../reviewData';
import { TrustpilotReview } from './TrustpilotReview';
export const TrustpilotReviewCarousel = () => {
    // states indexed at 0
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [maxReviewsPerPage, setMaxReviewPerPage] = useState(1);
    const { isMinLg, isMinXl } = useCoralBreakpoints();
    const isClient = useIsClient();
    useEffect(() => {
        setCarouselIndex(0);
    }, [maxReviewsPerPage]);
    switch (isClient) {
        case isMinXl:
            maxReviewsPerPage !== 3 && setMaxReviewPerPage(3);
            break;
        case isMinLg:
            maxReviewsPerPage !== 2 && setMaxReviewPerPage(2);
            break;
        default:
            maxReviewsPerPage !== 1 && setMaxReviewPerPage(1);
            break;
    }
    const isPrevDisabled = carouselIndex === 0;
    const isNextDisabled = carouselIndex + maxReviewsPerPage >= reviewData.length;
    const handleOnClick = (action) => {
        if (action === 'next') {
            setCarouselIndex(carouselIndex + 1);
        }
        else if (action === 'prev') {
            setCarouselIndex(carouselIndex - 1);
        }
        return;
    };
    return (_jsx(Container, { fullWidth: true, maxWidth: 600, md: { maxWidth: 'none' }, children: _jsxs(Stack, { fullWidth: true, alignItems: "center", fullHeight: true, gap: "xxs", sm: { gap: 'sm' }, md: { gap: 'md' }, children: [_jsx("div", { style: {
                        visibility: isPrevDisabled ? 'hidden' : 'visible',
                    }, children: _jsx(Button, { variant: "ghost", endIcon: _jsx(IconChevronLeft, {}), onClick: () => handleOnClick('prev') }) }), _jsx(Stack, { fullWidth: true, children: reviewData.map(({ title, body, name, date, url }, index) => {
                        if (index < carouselIndex ||
                            index >= carouselIndex + maxReviewsPerPage) {
                            return null;
                        }
                        return (_jsx(TrustpilotReview, { title: title, body: body, name: name, date: date, url: url }, index));
                    }) }), _jsx("div", { style: {
                        visibility: isNextDisabled ? 'hidden' : 'visible',
                    }, children: _jsx(Button, { variant: "ghost", endIcon: _jsx(IconChevronRight, {}), onClick: () => handleOnClick('next') }) })] }) }));
};
