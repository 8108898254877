var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
export const useTrustPilot = (businessAcronym) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [loading, setLoading] = useState(true);
    const [trustPilotResponse, setTrustPilotResponse] = useState();
    /**
     * Use a side-effect to fetch the Trust Pilot review data
     */
    useEffect(() => {
        const abortController = new AbortController();
        (() => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield fetch(`https://oe-trust-pilot.vercel.app/api/${businessAcronym}`, { signal: abortController.signal });
                if (response.ok) {
                    setTrustPilotResponse(yield response.json());
                    setLoading(false);
                }
            }
            catch (error) {
                /* istanbul ignore next */
                if (error instanceof DOMException &&
                    error.name == 'AbortError') {
                    console.error('TrustPilot: The request to fetch the Trust Pilot data encountered an error');
                    setLoading(false);
                }
            }
        }))();
        return () => {
            abortController.abort();
        };
    }, [businessAcronym]);
    const stars = (_b = (_a = trustPilotResponse === null || trustPilotResponse === void 0 ? void 0 : trustPilotResponse.score) === null || _a === void 0 ? void 0 : _a.stars) !== null && _b !== void 0 ? _b : 0;
    const trustScore = (_d = (_c = trustPilotResponse === null || trustPilotResponse === void 0 ? void 0 : trustPilotResponse.score) === null || _c === void 0 ? void 0 : _c.trustScore) !== null && _d !== void 0 ? _d : 0;
    const reviews = new Intl.NumberFormat().format((_f = (_e = trustPilotResponse === null || trustPilotResponse === void 0 ? void 0 : trustPilotResponse.numberOfReviews) === null || _e === void 0 ? void 0 : _e.total) !== null && _f !== void 0 ? _f : 0);
    const updatedAt = (_g = trustPilotResponse === null || trustPilotResponse === void 0 ? void 0 : trustPilotResponse.updatedAt) !== null && _g !== void 0 ? _g : '';
    return {
        loading,
        stars,
        trustScore,
        reviews,
        updatedAt,
    };
};
