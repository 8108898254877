import { jsx as _jsx } from "react/jsx-runtime";
import { useIsClient } from '@krakentech/utils';
import { useCoralBreakpoints } from '@krakentech/coral';
export const ReviewBody = ({ body }) => {
    const { isMaxMd } = useCoralBreakpoints();
    const isClient = useIsClient();
    const textStyles = {
        display: '-webkit-box',
        WebkitLineClamp: isMaxMd && isClient ? 5 : 3,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        color: '#F0FFFF',
        fontWeight: 400,
        fontSize: 16,
    };
    return (_jsx("p", { style: Object.assign({}, textStyles), children: body }));
};
