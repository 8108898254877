import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@krakentech/coral';
const TrustPilotStar1 = () => (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "224", height: "42", fill: "none", viewBox: "0 0 224 42", children: [_jsx("path", { fill: "#FF3722", d: "M0 0h42v42H0V0Z" }), _jsx("path", { fill: "#DCDCE6", d: "M45.5 0h42v42h-42V0ZM91 0h42v42H91V0Zm45.5 0h42v42h-42V0ZM182 0h42v42h-42V0Z" }), _jsx("path", { fill: "#fff", d: "m21 28.3062 6.3875-1.6187 2.6688 8.225L21 28.3062ZM35.7 17.675H24.4563L21 7.0875 17.5438 17.675H6.3l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025L35.7 17.675Zm30.8 10.6312 6.3875-1.6187 2.6688 8.225L66.5 28.3062ZM81.2 17.675H69.9563L66.5 7.0875 63.0438 17.675H51.8l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025L81.2 17.675ZM112 28.3062l6.388-1.6187 2.668 8.225L112 28.3062Zm14.7-10.6312h-11.244L112 7.0875l-3.456 10.5875H97.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625Zm30.8 10.6312 6.388-1.6187 2.668 8.225-9.056-6.6063Zm14.7-10.6312h-11.244L157.5 7.0875l-3.456 10.5875H142.8l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM203 28.3062l6.388-1.6187 2.668 8.225L203 28.3062Zm14.7-10.6312h-11.244L203 7.0875l-3.456 10.5875H188.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625Z" })] }));
const TrustPilotStar1Half = () => (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "224", height: "42", fill: "none", viewBox: "0 0 224 42", children: [_jsx("path", { fill: "#FF3722", d: "M42 0H0v42h42V0Z" }), _jsx("path", { fill: "#DCDCE6", d: "M87.1499 0h-42v42h42V0Z" }), _jsx("path", { fill: "#FF3722", d: "M66.1499 0h-21v42h21V0Z" }), _jsx("path", { fill: "#DCDCE6", d: "M133.35 0H91.3501v42H133.35V0ZM178.5 0h-42v42h42V0ZM223.65 0h-42v42h42V0Z" }), _jsx("path", { fill: "#fff", d: "m21 28.5687 6.3875-1.6187 2.6688 8.225L21 28.5687Zm14.7-10.6312H24.4563L21 7.35l-3.4562 10.5875H6.3L15.4 24.5l-3.4562 10.5875 9.1-6.5625 5.6-4.025L35.7 17.9375ZM66.15 28.5687l6.3875-1.6187 2.6687 8.225-9.0562-6.6063Zm14.7-10.6312H69.6062L66.15 7.35l-3.4563 10.5875H51.45L60.5499 24.5l-3.4562 10.5875 9.1-6.5625 5.6-4.025 9.0563-6.5625ZM112.35 28.5687l6.387-1.6187 2.669 8.225-9.056-6.6063Zm14.7-10.6312h-11.244L112.35 7.35l-3.456 10.5875H97.6499L106.75 24.5l-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM157.5 28.5687l6.388-1.6187 2.668 8.225-9.056-6.6063Zm14.7-10.6312h-11.244L157.5 7.35l-3.456 10.5875H142.8l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM202.65 28.5687l6.387-1.6187 2.669 8.225-9.056-6.6063Zm14.7-10.6312h-11.244L202.65 7.35l-3.456 10.5875H187.95l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625Z" })] }));
const TrustPilotStar2 = () => (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "224", height: "42", fill: "none", viewBox: "0 0 224 42", children: [_jsx("path", { fill: "#FF8622", d: "M42 0H0v42h42V0ZM87.5 0h-42v42h42V0Z" }), _jsx("path", { fill: "#DCDCE6", d: "M133 0H91v42h42V0ZM178.5 0h-42v42h42V0ZM224 0h-42v42h42V0Z" }), _jsx("path", { fill: "#fff", d: "m21 28.3063 6.3875-1.6188 2.6688 8.225L21 28.3063ZM35.7 17.675H24.4563L21 7.0875 17.5438 17.675H6.3l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025L35.7 17.675ZM66.5 28.3063l6.3876-1.6188 2.6687 8.225L66.5 28.3063ZM81.2001 17.675H69.9563L66.5 7.0875 63.0438 17.675H51.8l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025 9.0563-6.5625ZM112 28.3063l6.388-1.6188 2.668 8.225L112 28.3063Zm14.7-10.6313h-11.244L112 7.0875l-3.456 10.5875H97.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM157.5 28.3063l6.388-1.6188 2.668 8.225-9.056-6.6062Zm14.7-10.6313h-11.244L157.5 7.0875l-3.456 10.5875H142.8l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM203 28.3063l6.388-1.6188 2.668 8.225L203 28.3063Zm14.7-10.6313h-11.244L203 7.0875l-3.456 10.5875H188.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625Z" })] }));
const TrustPilotStar2Half = () => (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "224", height: "42", fill: "none", viewBox: "0 0 224 42", children: [_jsx("path", { fill: "#FF8622", d: "M42 0H0v42h42V0ZM87.5 0h-42v42h42V0Z" }), _jsx("path", { fill: "#DCDCE6", d: "M133 0H91v42h42V0Z" }), _jsx("path", { fill: "#FF8622", d: "M112 0H91v42h21V0Z" }), _jsx("path", { fill: "#DCDCE6", d: "M178.5 0h-42v42h42V0ZM224 0h-42v42h42V0Z" }), _jsx("path", { fill: "#fff", d: "m21 28.3063 6.3875-1.6188 2.6688 8.225L21 28.3063ZM35.7 17.675H24.4563L21 7.0875 17.5438 17.675H6.3l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025L35.7 17.675ZM66.5 28.3063l6.3876-1.6188 2.6687 8.225L66.5 28.3063ZM81.2001 17.675H69.9563L66.5 7.0875 63.0438 17.675H51.8l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025 9.0563-6.5625ZM112 28.3063l6.388-1.6188 2.668 8.225L112 28.3063Zm14.7-10.6313h-11.244L112 7.0875l-3.456 10.5875H97.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM157.5 28.3063l6.388-1.6188 2.668 8.225-9.056-6.6062Zm14.7-10.6313h-11.244L157.5 7.0875l-3.456 10.5875H142.8l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM203 28.3063l6.388-1.6188 2.668 8.225L203 28.3063Zm14.7-10.6313h-11.244L203 7.0875l-3.456 10.5875H188.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625Z" })] }));
const TrustPilotStar3 = () => (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "224", height: "42", fill: "none", viewBox: "0 0 224 42", children: [_jsx("path", { fill: "#FFCE00", d: "M42 0H0v42h42V0ZM87.5 0h-42v42h42V0ZM133 0H91v42h42V0Z" }), _jsx("path", { fill: "#DCDCE6", d: "M178.5 0h-42v42h42V0ZM224 0h-42v42h42V0Z" }), _jsx("path", { fill: "#fff", d: "m21 28.3063 6.3875-1.6188 2.6688 8.225L21 28.3063ZM35.7 17.675H24.4563L21 7.0875 17.5438 17.675H6.3l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025L35.7 17.675ZM66.5 28.3063l6.3876-1.6188 2.6687 8.225L66.5 28.3063ZM81.2001 17.675H69.9563L66.5 7.0875 63.0438 17.675H51.8l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025 9.0563-6.5625ZM112 28.3063l6.388-1.6188 2.668 8.225L112 28.3063Zm14.7-10.6313h-11.244L112 7.0875l-3.456 10.5875H97.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM157.5 28.3063l6.388-1.6188 2.668 8.225-9.056-6.6062Zm14.7-10.6313h-11.244L157.5 7.0875l-3.456 10.5875H142.8l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM203 28.3063l6.388-1.6188 2.668 8.225L203 28.3063Zm14.7-10.6313h-11.244L203 7.0875l-3.456 10.5875H188.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625Z" })] }));
const TrustPilotStar3Half = () => (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "224", height: "42", fill: "none", viewBox: "0 0 224 42", children: [_jsx("path", { fill: "#FFCE00", d: "M42 0H0v42h42V0ZM87.5 0h-42v42h42V0ZM133 0H91v42h42V0Z" }), _jsx("path", { fill: "#DCDCE6", d: "M178.5 0h-42v42h42V0Z" }), _jsx("path", { fill: "#FFCE00", d: "M157.5 0h-21v42h21V0Z" }), _jsx("path", { fill: "#DCDCE6", d: "M224 0h-42v42h42V0Z" }), _jsx("path", { fill: "#fff", d: "m21 28.3063 6.3875-1.6188 2.6688 8.225L21 28.3063ZM35.7 17.675H24.4563L21 7.0875 17.5438 17.675H6.3l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025L35.7 17.675ZM66.5 28.3063l6.3876-1.6188 2.6687 8.225L66.5 28.3063ZM81.2001 17.675H69.9563L66.5 7.0875 63.0438 17.675H51.8l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025 9.0563-6.5625ZM112 28.3063l6.388-1.6188 2.668 8.225L112 28.3063Zm14.7-10.6313h-11.244L112 7.0875l-3.456 10.5875H97.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM157.5 28.3063l6.388-1.6188 2.668 8.225-9.056-6.6062Zm14.7-10.6313h-11.244L157.5 7.0875l-3.456 10.5875H142.8l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM203 28.3063l6.388-1.6188 2.668 8.225L203 28.3063Zm14.7-10.6313h-11.244L203 7.0875l-3.456 10.5875H188.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625Z" })] }));
const TrustPilotStar4 = () => (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "224", height: "42", fill: "none", viewBox: "0 0 224 42", children: [_jsx("path", { fill: "#73CF11", d: "M42 0H0v42h42V0ZM87.5 0h-42v42h42V0ZM133 0H91v42h42V0ZM178.5 0h-42v42h42V0Z" }), _jsx("path", { fill: "#DCDCE6", d: "M224 0h-42v42h42V0Z" }), _jsx("path", { fill: "#fff", d: "m21 28.3063 6.3875-1.6188 2.6688 8.225L21 28.3063ZM35.7 17.675H24.4563L21 7.0875 17.5438 17.675H6.3l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025L35.7 17.675ZM66.5 28.3063l6.3876-1.6188 2.6687 8.225L66.5 28.3063ZM81.2001 17.675H69.9563L66.5 7.0875 63.0438 17.675H51.8l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025 9.0563-6.5625ZM112 28.3063l6.388-1.6188 2.668 8.225L112 28.3063Zm14.7-10.6313h-11.244L112 7.0875l-3.456 10.5875H97.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM157.5 28.3063l6.388-1.6188 2.668 8.225-9.056-6.6062Zm14.7-10.6313h-11.244L157.5 7.0875l-3.456 10.5875H142.8l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM203 28.3063l6.388-1.6188 2.668 8.225L203 28.3063Zm14.7-10.6313h-11.244L203 7.0875l-3.456 10.5875H188.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625Z" })] }));
const TrustPilotStar4Half = () => (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "224", height: "42", fill: "none", viewBox: "0 0 224 42", children: [_jsx("path", { fill: "#00B67A", d: "M42 0H0v42h42V0ZM87.5 0h-42v42h42V0ZM133 0H91v42h42V0ZM178.5 0h-42v42h42V0Z" }), _jsx("path", { fill: "#DCDCE6", d: "M224 0h-21v42h21V0Z" }), _jsx("path", { fill: "#00B67A", d: "M203 0h-21v42h21V0Z" }), _jsx("path", { fill: "#fff", d: "m21 28.3063 6.3875-1.6188 2.6688 8.225L21 28.3063ZM35.7 17.675H24.4563L21 7.0875 17.5438 17.675H6.3l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025L35.7 17.675ZM66.5 28.3063l6.3876-1.6188 2.6687 8.225L66.5 28.3063ZM81.2001 17.675H69.9563L66.5 7.0875 63.0438 17.675H51.8l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025 9.0563-6.5625ZM112 28.3063l6.388-1.6188 2.668 8.225L112 28.3063Zm14.7-10.6313h-11.244L112 7.0875l-3.456 10.5875H97.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM157.5 28.3063l6.388-1.6188 2.668 8.225-9.056-6.6062Zm14.7-10.6313h-11.244L157.5 7.0875l-3.456 10.5875H142.8l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM203 28.3063l6.388-1.6188 2.668 8.225L203 28.3063Zm14.7-10.6313h-11.244L203 7.0875l-3.456 10.5875H188.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625Z" })] }));
const TrustPilotStar5 = () => (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "224", height: "42", fill: "none", viewBox: "0 0 224 42", children: _jsxs("g", { children: [_jsx("path", { fill: "#00B67A", d: "M42 0H0v42h42V0ZM87.5 0h-42v42h42V0ZM133 0H91v42h42V0ZM178.5 0h-42v42h42V0ZM224 0h-42v42h42V0Z" }), _jsx("path", { fill: "#fff", d: "m21 28.3063 6.3875-1.6188 2.6688 8.225L21 28.3063ZM35.7 17.675H24.4563L21 7.0875 17.5438 17.675H6.3l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025L35.7 17.675ZM66.5 28.3063l6.3876-1.6188 2.6687 8.225L66.5 28.3063ZM81.2001 17.675H69.9563L66.5 7.0875 63.0438 17.675H51.8l9.1 6.5625-3.4562 10.5875 9.1-6.5625 5.6-4.025 9.0563-6.5625ZM112 28.3063l6.388-1.6188 2.668 8.225L112 28.3063Zm14.7-10.6313h-11.244L112 7.0875l-3.456 10.5875H97.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM157.5 28.3063l6.388-1.6188 2.668 8.225-9.056-6.6062Zm14.7-10.6313h-11.244L157.5 7.0875l-3.456 10.5875H142.8l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625ZM203 28.3063l6.388-1.6188 2.668 8.225L203 28.3063Zm14.7-10.6313h-11.244L203 7.0875l-3.456 10.5875H188.3l9.1 6.5625-3.456 10.5875 9.1-6.5625 5.6-4.025 9.056-6.5625Z" })] }) }));
export const TrustPilotStars = ({ stars }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const starMap = {
        1: _jsx(TrustPilotStar1, {}),
        1.5: _jsx(TrustPilotStar1Half, {}),
        2: _jsx(TrustPilotStar2, {}),
        2.5: _jsx(TrustPilotStar2Half, {}),
        3: _jsx(TrustPilotStar3, {}),
        3.5: _jsx(TrustPilotStar3Half, {}),
        4: _jsx(TrustPilotStar4, {}),
        4.5: _jsx(TrustPilotStar4Half, {}),
        5: _jsx(TrustPilotStar5, {}),
    };
    return _jsx(Stack, { gap: "xxs", children: starMap[stars] });
};
