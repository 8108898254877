export const reviewData = [
    {
        title: 'Excellent supplier',
        body: "Octopus Energy is so much better than any other energy company I've been with. Communication is very clear - whenever I've had a question I've just emailed and got a quick reply. They always email you straight away to let you know about any changes to your payments. It's very easy to keep track of your spending on your account. I also love the Saving Sessions and spinning the wheel to win points. We are moving soon and will definitely stay with Octopus in our new house. Thank you for the green energy and amazing customer service!",
        name: 'Ellie McIntyre',
        date: '03 February 2025',
        url: 'https://uk.trustpilot.com/reviews/67a116afc1bd0168252228c9',
    },
    {
        title: 'So easy to swap and save money.',
        body: 'The gentleman that I spoke to couldn’t have been nicer. Friendly, easy to ask anything you felt you needed to, without being judged, and helpful with the information he provided. Very knowledgeable. Very glad we have signed up, already know we will save money from our previous provider.',
        name: 'Julie',
        date: '30 Jan 2025',
        url: 'https://uk.trustpilot.com/reviews/67a111f945fcd367cf91f810',
    },
    {
        title: 'Another great experience with Octopus...',
        body: 'Another great experience with Octopus customer services. Spoke to Alex who dealt with my query very quickly and efficiently with email confirmation of the changes requested received pretty much immediately. Just great customer service all round and wish other companies would follow their lead and be this easy to deal with',
        name: 'waynelepper',
        date: '03 February 2025',
        url: 'https://uk.trustpilot.com/reviews/67a108bbf97996f740ef0be9',
    },
    {
        title: 'Brilliant app',
        body: 'I just really like the app and the ease with which I can submit readings and check my account. I appreciate the £50 bonus for every friend who signs up with Octopus.',
        name: 'Zannah Lewis',
        date: '04 February 2025',
        url: 'https://uk.trustpilot.com/reviews/67a1ce5175f7ece44ac72b47',
    },
    {
        title: 'Good advise on EV charging',
        body: 'Good advise on EV charging, type of EV chargers that have a good brand & much cheaper rates than my last supplier.',
        name: 'GWEN PIRIE',
        date: '03 February 2025',
        url: 'https://uk.trustpilot.com/reviews/67a11e3a0fcc60b9fbc58f01',
    },
    {
        title: 'Been with Octopus for years now and...',
        body: 'Been with Octopus for years now and always great customer service and good rates, quick and efficient with everything. Really happy all round. Thank you 😊',
        name: 'Arleta Mikrut',
        date: '04 February 2025',
        url: 'https://uk.trustpilot.com/reviews/67a19d590317a48eaad2debe',
    },
    {
        title: 'Best energy supplier I’ve been with',
        body: 'Best energy supplier I’ve been with. Easy app to use and great choice of tariffs',
        name: 'Jac',
        date: '03 February 2025',
        url: 'https://uk.trustpilot.com/reviews/67a154515cb7053386f8f929',
    },
];
