import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Grid, Stack, Typography, useCoralTheme, Visibility, } from '@krakentech/coral';
import { IconSearch } from '@krakentech/icons';
import { useState } from 'react';
import styled from 'styled-components';
export const JoinOctopus = () => {
    const [postcode, setPostcode] = useState('');
    const theme = useCoralTheme();
    const handleChange = (event) => {
        const { value } = event.target;
        setPostcode(value);
    };
    return (_jsxs(Stack, { direction: "vertical", children: [_jsx(Typography, { variant: "h2", color: "tertiary", textWrap: "pretty", component: "h1", children: _jsx("strong", { children: "Join the UK's most popular energy supplier" }) }), _jsx(Typography, { children: "Enter your postcode to get a quote:" }), _jsx(Container, { maxWidth: 300, sm: { maxWidth: 'full' }, children: _jsx("form", { action: `/quote?postcode=${postcode}`, children: _jsxs(Grid, { gap: "none", templateColumns: "1fr 75px", sm: { templateColumns: '1fr 160px' }, children: [_jsx(CustomInput, { "aria-label": "Your postcode", name: "postcode", theme: theme, placeholder: "Your postcode", onChange: (event) => handleChange(event) }), _jsxs(CustomButton, { type: "submit", theme: theme, children: [_jsx(Visibility, { hidden: true, display: "none", sm: { hidden: false, display: 'block' }, children: _jsx(Typography, { variant: "button", color: "dark", children: _jsx("strong", { children: "Get a quote" }) }) }), _jsx(Visibility, { hidden: false, sm: { hidden: true, display: 'none' }, children: _jsx(Stack, { justifyContent: "center", alignItems: "center", children: _jsx(IconSearch, { color: "#180048", size: 20 }) }) })] })] }) }) })] }));
};
const CustomInput = styled.input `
    display: inline-block;
    width: 100%;
    background-color: #f0ffff;
    box-shadow: none;
    color: ${({ theme }) => theme.color.text.dark};
    font-weight: 500;
    ::placeholder {
        color: #776aa9;
    }
    border: none;
    border-radius: ${({ theme }) => `${theme.borderRadius} 0 0 ${theme.borderRadius}`};
    padding: ${({ theme }) => `${theme.spacing.sm} 0 ${theme.spacing.sm} ${theme.spacing.sm}`};

    @media (max-width: 576px) {
        max-height: 50px;
    }
    @media (min-width: 768px) {
        padding: ${({ theme }) => `${theme.spacing.md} 0 ${theme.spacing.md} ${theme.spacing.sm}`};
    }
`;
const CustomButton = styled.button `
    display: inline-block;
    cursor: pointer;
    box-shadow: none;
    background-color: #f050f8;
    color: ${({ theme }) => theme.color.text.dark};
    padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
    border-radius: ${({ theme }) => `0 ${theme.borderRadius} ${theme.borderRadius} 0`};
    border: none;
    :hover {
        background-color: ${({ theme }) => theme.color.primary.light};
    }
    @media (max-width: 576px) {
        max-height: 50px;
    }
    @media (min-width: 768px) {
        padding: ${({ theme }) => theme.spacing.md};
    }
    @media (min-width: 1200px) {
        padding: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.sm}`};
    }
`;
